* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Montserrat;
}

a:hover {
  cursor: pointer;
}

body {
    /* background-color: #4EABBD; */
    background-color: #818589;
    /* color: white; */
}

.btn {
  font-weight: bold;
}

form {
  /* border: 1px solid black; */
  border-radius: 15px;
  padding: 20px;
}

img {
  border: 10px solid black;
}

/* HTML Elements */





/* Standard CSS Classes */
.addVehicle input {
  /* this is handled in the panel */
  /* border-radius: 5px; */
}

.bg {
  background-image: url("./images//jeep.png");
  background-position: center;
}

.card {
    width: 18rem;
}

.cardImg {
  border: none;
}

.cardTitle {
  min-height:80px;
  font-weight: bold;
  display:flex;
}

.center {
  height: 100vh;
}

.header {
  /* padding: -20px; */
  /* border-radius: 10px 10px 0 0; */
  /* background-color: white; */
  background-color: #3A3A3A;
  color: white;
  /* border-bottom: 5px solid black; */
  /* margin-bottom: 50px; */
}

.headerLogo {
  width: 150px;
}

.footer {
  /* padding: 10px; */
  /* margin: -20px; */
  /* padding: 20px; */
  /* border-radius: 0 0 10px 10px; */
  /* background-color: #4EABBD;
  color: white; */
  background-color: white;
  /* color: #4EABBD; */
  color: black;
  
  /* border-top: 5px solid black; */
  margin-top: 55px;
}

.footerBox {
  /* border-left: 1px solid black; */
  /* border-right: 1px solid black; */
  /* width: 33%; */
  /* padding-left: 10px; */
  /* border: 1px solid black; */
  /* padding: 10px; */
}

.footerAcknowledgements {
  font-size: 12px;
  /* text-align: right; */
}

.footerAcknowledgements a {
  /* color: #4EABBD; */
  color: black;
  text-decoration: none;
}

.headerPanel {
    /* header panel being the header or nav module */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.icons {
  border: none;
}

.landingPage {
  height: 100vh;
}

.logo {
  /* border: 5px solid black; */
  border: none;
  /* width: 150px; */
  /* border-radius: 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* margin: 0 0 20px 0; */
}

.panel {
    height: auto;
    /* border: 5px solid black; */
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
    /* background-color: rgb(231, 227, 227); */
    background-color: #D3D3D3;
    /* color: #4EABBD; */
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.panelHeading {
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    /* margin: 20px; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* border: 1px solid red; */
}

.specialMessage {
  padding: 20px;
  text-align: center;
}

.svlImg {
  /* Single Vehicle Layout Image section */
  margin: 0 10px;
}

.vehicleDetails {
  /* same class used for service record details */
  line-height: 1.5rem;
  color: black;
  font-size: 22px;
  width: 100%;
  /* margin: 10px; */
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vehicleDetails input {
  width: 100%;
}

.vehicleDetails span {
  /* color: #4EABBD; */
  color: #899499;
  font-weight: bold;
  font-size: 18px;
}

.vehicleImg {
  width: 400px;
  height: 300px;
}

.vdets {
  border: 1px solid black;
}

.temp {
    height: 100px;
    border: 10px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
}







@media screen and (max-width: 768px) {

    .card {
        width: 22rem;
    }
    
    .footer {
      display: flex;
      flex-direction: column;
    }

    .footerBox {
      border-left: none;
      border-right: none;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }

    .headerFont {
        font-size: 14px;
    }

    .headerLogo {
      width: 150px;
    }

    .icons {
      width: 48px;
      margin-left: 10px;
    }

    .landingPage {
      height: 100%;
    }

    .mobileLayout {
      display: flex;
      flex-direction: column;
    }

    .serviceTotal {
      flex-direction: column;
    }

    .singleVehicleLayout {
      display: flex;
      flex-direction: column;
    }

    .svlImg, .vehicleDetails {
      margin: 0;
    }

    .singleVehicleLayout img {
      width: 100%;
    }

  
}